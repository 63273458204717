import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import About from "./containers/About";
import MyGallery from "./containers/MyGallery";
import Blog from "./containers/Blog";

class Routes extends Component {
  state = {};
  render() {
    return (
      <Switch>
        <Route exact path="/">
          <MyGallery />
        </Route>
        <Route exact path="/gallery">
          <MyGallery />
        </Route>
        <Route exact path="/about">
          <About />
        </Route>
        <Route exact path="/blog">
          <Blog />
        </Route>
      </Switch>
    );
  }
}

export default Routes;
