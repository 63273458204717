import React, { Component } from "../../node_modules/react";
import { Nav } from "../../node_modules/react-bootstrap";

class Sidebar extends Component {
  state = {
    photoCollections: [
      { id: 1, name: "Light After Dark" },
      { id: 2, name: "Around Van" },
      { id: 3, name: "Child Art" },
    ],
  };

  render() {
    return (
      <div>
        <span
          style={{
            paddingInlineStart: "15px",
            fontWeight: "bold",
          }}
        >
          Collections
        </span>
        <ul style={{ paddingInlineStart: "20px", marginTop: "7px" }}>
          {this.state.photoCollections.map((photoCollection) => (
            <Nav.Link
              style={{
                color: "black",
                fontSize: "14px",
                padding: "2px",
              }}
              key={photoCollection.id}
              onClick={() => this.props.handleClick(photoCollection.id)}
            >
              {photoCollection.name}
            </Nav.Link>
          ))}
        </ul>
      </div>
    );
  }
}

export default Sidebar;
