import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import MyNavbar from "./components/MyNavbar";
import MyContent from "./components/MyContent";
import Sidebar from "./components/Sidebar";
import { Link } from "react-router-dom";

class App extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-12">
            <MyNavbar />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default App;
