import React, { useState, useCallback } from "../../node_modules/react";
import "../../node_modules/bootstrap/dist/css/bootstrap.css";
import "../css/MyContent.css";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

function MyContent(props) {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  // getEightBallResponse = () => {
  //   fetch("https://8ball.delegator.com/magic/JSON/Should I pay this employee?")
  //     .then((response) => response.json())
  //     .then((json) => {
  //       console.log(json);
  //       this.setState({ myVariable: json.magic.answer });
  //     });
  // };

  return (
    <div>
      <Gallery photos={props.photoList} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={props.photoList.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}

export default MyContent;
