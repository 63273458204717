export const aroundVanPhotos = [
  {
    src:
      "http://www.thekwans.ca/imgapp/upload/2020/06/11/20200611213427-f35e66f0.jpg",
    width: 3,
    height: 2,
  },
  {
    src:
      "http://www.thekwans.ca/imgapp/upload/2020/06/11/20200611213100-009ea2f5.jpg",
    width: 3,
    height: 2,
  },
  {
    src:
      "http://www.thekwans.ca/imgapp/upload/2020/06/11/20200611213104-14bb467d.jpg",
    width: 3,
    height: 2,
  },
  {
    src:
      "http://www.thekwans.ca/imgapp/upload/2020/06/11/20200611213037-e33412ed.jpg",
    width: 3,
    height: 2,
  },
  {
    src:
      "http://www.thekwans.ca/imgapp/upload/2020/06/11/20200611212638-9ddb05ee.jpg",
    width: 3,
    height: 2,
  },
  {
    src:
      "http://www.thekwans.ca/imgapp/upload/2020/06/11/20200611212629-96e1eaf2.jpg",
    width: 3,
    height: 2,
  },
  {
    src:
      "http://www.thekwans.ca/imgapp/upload/2020/06/11/20200611212625-ca439f96.jpg",
    width: 3,
    height: 2,
  },
  {
    src:
      "http://www.thekwans.ca/imgapp/upload/2020/06/11/20200611212610-a3e2d2ab.jpg",
    width: 3,
    height: 2,
  },
];
