import React, { Component } from "react";
import Sidebar from "../components/Sidebar";
import MyContent from "../components/MyContent";
import { lightAfterDarkPhotos } from "../lightAfterDarkPhotos";
import { aroundVanPhotos } from "../aroundVanPhotos";

class MyGallery extends React.Component {
  state = {
    photoList: lightAfterDarkPhotos,
  };

  handleClick = (photosIndex) => {
    switch (photosIndex) {
      case 1:
        this.setState({ photoList: lightAfterDarkPhotos });
        break;
      case 2:
        this.setState({ photoList: aroundVanPhotos });
        break;
      case 3:
        this.setState({ photoList: [] });
        break;
    }
  };

  render() {
    return (
      <div className="Home">
        <div className="row">
          <div
            className="col-sm-2 col-md-2 col-lg-2"
            style={{ minHeight: 100 }}
          >
            <Sidebar handleClick={this.handleClick} />
          </div>
          <div className="col-sm-10 col-md-10 col-lg-10">
            <MyContent photoList={this.state.photoList} />
          </div>
        </div>
      </div>
    );
  }
}

export default MyGallery;
