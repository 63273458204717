import React from "../../node_modules/react";
import "../../node_modules/bootstrap/dist/css/bootstrap.css";
import { Navbar, Nav } from "../../node_modules/react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Routes from "../Routes";

class MyNavbar extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Navbar bg="white" expand="sm">
          <Navbar.Brand href="#home">
            <img
              src={require("../images/siteLogo.png")}
              style={{ height: 46 }}
            ></img>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <LinkContainer to="/about">
                <Nav.Link>About</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/gallery">
                <Nav.Link>Gallery</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/blog">
                <Nav.Link>Blog</Nav.Link>
              </LinkContainer>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Routes />
      </React.Fragment>
    );
  }
}

export default MyNavbar;
