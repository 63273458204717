import React, { Component } from "react";
import "../css/About.css";
import desktopImage from "../images/aboutBackground.jpg";
import mobileImage from "../images/aboutBackground.jpg";

const About = () => {
  const imageUrl = window.innerWidth >= 650 ? desktopImage : mobileImage;

  return (
    <div className="App" style={{ backgroundImage: `url(${imageUrl})` }}>
      <div className="App-content" style={{ color: "white" }}>
        <h3>Info</h3>
        <span className="aSpan">
          <b>Location //</b> Burnaby, BC
        </span>
        <span className="aSpan">
          <b>Occupation //</b> Coding and Stuff
        </span>
        <span className="aSpan">
          <b>Linked In // </b>
          <a
            style={{ color: "white" }}
            href="https://www.linkedin.com/in/mikegkwan"
            target="_blank"
          >
            mikegkwan
          </a>
        </span>
      </div>
    </div>
  );
};

export default About;
