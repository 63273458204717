import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";

class Blog extends React.Component {
  state = {};
  render() {
    return (
      <Row>
        <Col md={1} style={{ marginLeft: 10, textAlign: "center" }}>
          <label>A test blog entry - Jan 1, 2020</label>
        </Col>
        <Col md={5} style={{ marginLeft: 10 }}>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam et
            nulla quis tellus elementum porta non sit amet ligula. Praesent
            posuere eget neque vel accumsan. Ut laoreet arcu massa, facilisis
            sollicitudin augue vehicula et. Donec sit amet dui mauris. Maecenas
            urna nisl, semper non justo elementum, imperdiet aliquam magna.
            Curabitur luctus urna non tortor efficitur porttitor. Aliquam tempus
            malesuada urna, id cursus nulla egestas in. Lorem ipsum dolor sit
            amet, consectetur adipiscing elit. Integer lobortis augue eget nisl
            viverra, ac convallis arcu iaculis. Aliquam pretium mauris dolor,
            sed tempus erat consequat egestas. Cras vulputate dolor mattis
            libero porta dignissim. Integer et faucibus felis, eu pharetra
            velit.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam et
            nulla quis tellus elementum porta non sit amet ligula. Praesent
            posuere eget neque vel accumsan. Ut laoreet arcu massa, facilisis
            sollicitudin augue vehicula et. Donec sit amet dui mauris. Maecenas
            urna nisl, semper non justo elementum, imperdiet aliquam magna.
            Curabitur luctus urna non tortor efficitur porttitor. Aliquam tempus
            malesuada urna, id cursus nulla egestas in. Lorem ipsum dolor sit
            amet, consectetur adipiscing elit. Integer lobortis augue eget nisl
            viverra, ac convallis arcu iaculis. Aliquam pretium mauris dolor,
            sed tempus erat consequat egestas. Cras vulputate dolor mattis
            libero porta dignissim. Integer et faucibus felis, eu pharetra
            velit.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam et
            nulla quis tellus elementum porta non sit amet ligula. Praesent
            posuere eget neque vel accumsan. Ut laoreet arcu massa, facilisis
            sollicitudin augue vehicula et. Donec sit amet dui mauris. Maecenas
            urna nisl, semper non justo elementum, imperdiet aliquam magna.
            Curabitur luctus urna non tortor efficitur porttitor. Aliquam tempus
            malesuada urna, id cursus nulla egestas in. Lorem ipsum dolor sit
            amet, consectetur adipiscing elit. Integer lobortis augue eget nisl
            viverra, ac convallis arcu iaculis. Aliquam pretium mauris dolor,
            sed tempus erat consequat egestas. Cras vulputate dolor mattis
            libero porta dignissim. Integer et faucibus felis, eu pharetra
            velit.
          </p>
        </Col>
        <Col md={6}></Col>
      </Row>
    );
  }
}

export default Blog;
