export const lightAfterDarkPhotos = [
  {
    src:
      "http://www.thekwans.ca/imgapp/upload/2020/06/11/20200611212655-86690b14.jpg",
    width: 3,
    height: 2,
  },
  {
    src:
      "http://www.thekwans.ca/imgapp/upload/2020/06/11/20200611213055-91d307cb.jpg",
    width: 3,
    height: 2,
  },
  {
    src:
      "http://www.thekwans.ca/imgapp/upload/2020/06/11/20200611213029-b7a2d7fa.jpg",
    width: 3,
    height: 2,
  },
  {
    src:
      "http://www.thekwans.ca/imgapp/upload/2020/06/11/20200611212652-fea34d20.jpg",
    width: 3,
    height: 2,
  },
  {
    src:
      "http://www.thekwans.ca/imgapp/upload/2020/06/11/20200611213052-f3a5e6cb.jpg",
    width: 3,
    height: 2,
  },
  {
    src:
      "http://www.thekwans.ca/imgapp/upload/2020/06/11/20200611213033-b8e9ec9c.jpg",
    width: 3,
    height: 2,
  },
  {
    src:
      "http://www.thekwans.ca/imgapp/upload/2020/06/11/20200611213040-58dbea52.jpg",
    width: 3,
    height: 2,
  },
  {
    src:
      "http://www.thekwans.ca/imgapp/upload/2020/06/11/20200611212616-83f13be7.jpg",
    width: 3,
    height: 2,
  },
  {
    src:
      "http://www.thekwans.ca/imgapp/upload/2020/06/11/20200611213312-c3cdacb3.jpg",
    width: 3,
    height: 2,
  },
  {
    src:
      "http://www.thekwans.ca/imgapp/upload/2020/06/11/20200611213321-3a29c329.jpg",
    width: 3,
    height: 2,
  },
  {
    src:
      "http://www.thekwans.ca/imgapp/upload/2020/06/11/20200611213026-4ce6ee33.jpg",
    width: 3,
    height: 2,
  },
  {
    src:
      "http://www.thekwans.ca/imgapp/upload/2020/06/11/20200611213325-48636c84.jpg",
    width: 3,
    height: 2,
  },
  {
    src:
      "http://www.thekwans.ca/imgapp/upload/2020/06/11/20200611212648-2951b412.jpg",
    width: 3,
    height: 2,
  },
  {
    src:
      "http://www.thekwans.ca/imgapp/upload/2020/06/11/20200611212641-6eade338.jpg",
    width: 3,
    height: 2,
  },
];
